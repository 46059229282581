<template>
  <div class="pageContol largeScreen" style="background:transparent">
    <div
      v-if="percentagebool"
      style="width:100%;height:100%;display:flex;align-items:center;justify-content:center;background:#141e38;position:fixed;top:0;left:0;z-index:1000000"
    >
      <div style="width:50%;">
        <el-progress
          :text-inside="true"
          :stroke-width="15"
          :percentage="percentage"
        ></el-progress>
        <p style="color:#fff;font-size:35px;text-align:center">Loading...</p>
      </div>
    </div>
    <div class="bgc">
      <header>
        <div class="header">
          <div
            style="width:40%;padding-left:5rem;margin-bottom: 10px;margin-top: 10px;"
          >
            <h3>北京晟融信息技术有限公司</h3>
          </div>
          <div
            style="width:60%;display: flex;flex-direction: column;align-items: center;margin-bottom: 1rem;"
          >
            <h2 style="font-size:22px">{{ compName }}</h2>
            <h2 style="font-size:26px">全员培训数据可视化展示</h2>
          </div>
          <div style="width:40%;margin-bottom: 10px;" class="weekBox">
            <h2 style="font-size:32px">{{ nowTime }}</h2>
            <div>
              <p>{{ nowWeek }}</p>
              <p>{{ nowDate }}</p>
            </div>
          </div>
        </div>
      </header>
      <section class="sectionBox">
        <div class="largebox" style="width:40%">
          <div class="largeLeft">
            <div class="largeLeft_container" style="height:33%">
              <div class="largeLeft_container_border">
                <!-- <div id="echart1" style="width:100%;height:100%"></div> -->
                <div
                  class="largeLeftPiebox"
                  style="display:flex;flex-direction: column;padding: 0 2.35rem"
                >
                  <div style="display:flex;">
                    <h3 class="titleH3">
                      培训班级进度
                    </h3>
                    <div style="width:69%;padding-top: 1rem; margin-left:2rem">
                      <img src="../../assets/linepc.png" alt="" width="100%" />
                    </div>
                  </div>

                  <div
                    style="display: flex;flex-direction: column;flex: 1 1 0%; ;height: 48%;overflow: hidden;margin-bottom:1rem"
                  >
                    <div @click="clickProps1($event)">
                      <vue-seamless-scroll
                        :data="projessList"
                        class="seamless-warp"
                        :class-option="classOption2"
                      >
                        <div style="width:100%;height:100%">
                          <ul>
                            <li
                              v-for="(item, index) in projessList"
                              :key="index"
                              style="margin-top:.25rem;cursor: pointer;"
                            >
                              <span
                                style="color:#fff"
                                :data-obj="JSON.stringify(item)"
                                :id="1"
                                >{{ item.projectName }}</span
                              >
                              <div
                                style="width:100%;padding:0 2.5rem 0 0;display:flex;align-items:center"
                              >
                                <div
                                  style="height:0.25rem;background:#65E3AC;position:relative"
                                  :style="{
                                    width: item.percentage * 100 + '%',
                                  }"
                                  v-if="index % 2 == 0"
                                  :data-obj="JSON.stringify(item)"
                                  :id="1"
                                >
                                  <img
                                    src="../../assets/circle.png"
                                    alt=""
                                    style="position:absolute;right:-7px;width:1rem;height:1rem;top:-6px"
                                  />
                                </div>

                                <div
                                  style="height:0.25rem;background:#4C61FF;position:relative"
                                  :style="{
                                    width: item.percentage * 100 + '%',
                                  }"
                                  :data-obj="JSON.stringify(item)"
                                  :id="1"
                                  v-else
                                >
                                  <img
                                    src="../../assets/circle1.png"
                                    alt=""
                                    style="position:absolute;right:-7px;width:1rem;height:1rem;top:-6px"
                                  />
                                </div>
                                <span
                                  style="color:#fff;padding-right: 1rem;padding-left:.5rem"
                                  >{{
                                    (item.percentage * 100).toFixed(0) + "%"
                                  }}</span
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                      </vue-seamless-scroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="largeLeft_container">
              <div class="largeLeft_container_border">
                <div
                  class="largeLeftPiebox"
                  style="display:flex;flex-direction: column;padding: 0 2.35rem"
                >
                  <div style="display:flex;">
                    <h3 class="titleH3">
                      岗位培训数据分析
                    </h3>
                    <div style="width:60%;padding-top: 1rem; margin-left:2rem">
                      <img src="../../assets/linepc.png" alt="" width="100%" />
                    </div>
                  </div>
                  <div style="display:flex;height: 80%;">
                    <div class="largepie">
                      <div id="pieEchart" style="width:100%;height:100%"></div>
                    </div>
                    <div class="largepie">
                      <div id="pieEchart1" style="width:100%;height:100%"></div>
                    </div>
                  </div>
                  <div style="height: 26%;padding-bottom: 0.5rem;">
                    <ul style="display:flex;height:100%" class="listul">
                      <li v-for="(item, index) in postList" :key="index">
                        <span
                          style="width:10px;height:10px;border-radius:50%;margin-right:5px"
                          :style="
                            item.postName == '三级教育培训'
                              ? 'background:#8f52ff;'
                              : item.postName == '年度安全再培训'
                              ? 'background:#FFAE00;'
                              : item.postName == '“四新”安全培训'
                              ? 'background:#F53F8C;'
                              : item.postName == '专项培训'
                              ? 'background:#FFFD52;'
                              : item.postName == '调岗和重新上岗培训'
                              ? 'background:#28B1FF;'
                              : 'background:#52FF97;'
                          "
                        ></span
                        >{{ item.postName }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="largeLeft_container" style="height: 33%;">
              <div class="largeLeft_container_border">
                <div
                  style="display:flex;flex-direction: column;height: 100%; padding: 0 2.35rem;padding-bottom:1rem"
                >
                  <div style="display:flex;">
                    <h3 class="titleH3">
                      培训班级
                    </h3>
                    <div style="width:70%;padding-top: 1rem; margin-left:2rem">
                      <img src="../../assets/linepc.png" alt="" width="100%" />
                    </div>
                  </div>
                  <div
                    style="display: flex;flex-direction: column;flex:1;height: 48%;overflow: hidden;"
                  >
                    <ul style="display:flex; color:#52FFFF" class="ulTitle">
                      <li
                        class="projectNum"
                        style="border-bottom: 0.05rem solid #024058"
                      >
                        <span>班级名称</span>
                        <span>培训状态</span><span>培训人数</span>
                        <span>完成人数</span>
                        <span>培训进度</span>
                      </li>
                    </ul>
                    <!-- <div> -->
                    <div @click="clickProps1($event)">
                      <vue-seamless-scroll
                        :data="TrainProjectList"
                        class="seamless-warp"
                        :class-option="classOption"
                      >
                        <ul
                          style="display:flex;flex-direction: column"
                          class="ulTitle projectNumcontent"
                        >
                          <li
                            v-for="(item, index) in TrainProjectList"
                            style="border-bottom: 0.05rem solid #024058"
                            :key="index"
                          >
                            <span
                              style="text-align:left"
                              :data-obj="JSON.stringify(item)"
                              :id="2"
                              >{{ item.projectName }}</span
                            >
                            <!-- <span>{{ item.trainTime }}</span> -->
                            <span
                              :data-obj="JSON.stringify(item)"
                              :id="2"
                              :style="{
                                color:
                                  item.projectState == '50'
                                    ? '#52FF97'
                                    : item.projectState == '10'
                                    ? '#CECECE'
                                    : item.projectState == '60'
                                    ? '#F53F8C'
                                    : '',
                              }"
                              >{{
                                $setDictionary(
                                  "PROJECTSTATE",
                                  item.projectState
                                )
                              }}</span
                            >
                            <span :data-obj="JSON.stringify(item)" :id="2">{{
                              item.totalPeopleNum
                            }}</span>
                            <span :data-obj="JSON.stringify(item)" :id="2">{{
                              item.completeNum
                            }}</span>
                            <span :data-obj="JSON.stringify(item)" :id="2">{{
                              item.percentage + "%"
                            }}</span>
                          </li>
                        </ul>
                      </vue-seamless-scroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="largebox" style="width:60%">
          <div class="largeLeft">
            <div class="largeLeft_container">
              <div
                class="largeLeft_container_border"
                style="display: flex;flex-direction: column;"
              >
                <div
                  style="padding: .5rem 2.35rem 0 2.35rem;display: flex;justify-content: space-between; align-items: center;"
                >
                  <div style="display:flex;">
                    <h3 class="titleH3">
                      数据汇总
                    </h3>
                    <div style="width:70%;padding-top: 1rem; margin-left:2rem">
                      <img src="../../assets/linepc.png" alt="" width="100%" />
                    </div>
                  </div>
                  <div class="btns">
                    <span
                      @click="SummarySwitch('1')"
                      :class="switchs == '1' ? 'btnbg' : ''"
                      >累计</span
                    >
                    <span
                      @click="SummarySwitch('2')"
                      :class="switchs == '2' ? 'btnbg' : ''"
                      >本年</span
                    >
                    <span
                      @click="SummarySwitch('3')"
                      :class="switchs == '3' ? 'btnbg' : ''"
                      >培训中</span
                    >
                  </div>
                </div>
                <div style="height:100%;overflow:hidden">
                  <!-- <transition name="el-zoom-in-bottom"> -->
                  <div
                    style="display:flex;flex-warp:warp;height: 100%;align-items: center;justify-content: center;"
                    class="largeLeft_container_center"
                    v-show="switchs == 1"
                  >
                    <div
                      style="display:flex;flex-direction: column;width: 100%;height: 100%;align-items: center;justify-content: space-around;padding-bottom: 1rem;"
                    >
                      <div>
                        <p
                          style="font-size:16px;color:#fff;padding-bottom:.65rem"
                        >
                          累计开班数量
                        </p>
                        <div style="display: flex;align-items: flex-end;">
                          <div class="box-item">
                            <li
                              :class="{
                                'number-item': !isNaN(item),
                                'mark-item': isNaN(item),
                              }"
                              v-for="(item, index) in totalProjectNumArr"
                              :key="index"
                              @click="jumpClass(false, false)"
                            >
                              <span v-if="!isNaN(item)">
                                <i ref="numberItem1">0123456789</i>
                              </span>
                              <span class="comma" v-else>{{ item }}</span>
                            </li>
                          </div>
                          <span
                            style="width:6rem;font-size:2rem;background: linear-gradient( 0deg, #ffffff 0%, #52ffff 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                            >个</span
                          >
                        </div>
                      </div>
                      <div>
                        <p
                          style="font-size:16px;color:#fff;padding-bottom:.65rem"
                        >
                          累计培训学员数量
                        </p>
                        <div style="display: flex;align-items: flex-end;">
                          <div class="box-item">
                            <li
                              :class="{
                                'number-item': !isNaN(item),
                                'mark-item': isNaN(item),
                              }"
                              v-for="(item, index) in totalProjectUserNumArr"
                              :key="index"
                              @click="jumpStudentList(false)"
                            >
                              <span v-if="!isNaN(item)">
                                <i ref="numberItem2">0123456789</i>
                              </span>
                              <span class="comma" v-else>{{ item }}</span>
                            </li>
                          </div>
                          <span
                            style="width:6rem;font-size:2rem;background: linear-gradient( 0deg, #ffffff 0%, #52ffff 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                            >人</span
                          >
                        </div>
                      </div>
                      <div>
                        <p
                          style="font-size:16px;color:#fff;padding-bottom:.65rem"
                        >
                          累计培训课时
                        </p>
                        <div style="display: flex;align-items: flex-end;">
                          <div class="box-item">
                            <li
                              :class="{
                                'number-item': !isNaN(item),
                                'mark-item': isNaN(item),
                              }"
                              v-for="(item, index) in totalProjectLessonNumArr"
                              :key="index"
                              @click="jumpstudylessnum(false)"
                            >
                              <span v-if="!isNaN(item)">
                                <i ref="numberItem3">0123456789</i>
                              </span>
                              <span class="comma" v-else>{{ item }}</span>
                            </li>
                          </div>
                          <span
                            style="width:6rem;font-size:2rem;background: linear-gradient( 0deg, #ffffff 0%, #52ffff 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                            >课时</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- </transition> -->
                  <!-- <transition name="el-zoom-in-top"> -->
                  <div
                    style="display:flex;flex-warp:warp;height: 100%;align-items: center;justify-content: center;"
                    class="largeLeft_container_center"
                    v-show="switchs == 2"
                  >
                    <div
                      style="display:flex;flex-direction: column;width: 100%;height: 100%;align-items: center;justify-content: space-around;padding-bottom: 1rem;"
                    >
                      <div>
                        <p
                          style="font-size:16px;color:#fff;padding-bottom:.65rem"
                        >
                          本年开班数量
                        </p>
                        <div style="display: flex;align-items: flex-end;">
                          <div class="box-item">
                            <li
                              :class="{
                                'number-item': !isNaN(item),
                                'mark-item': isNaN(item),
                              }"
                              v-for="(item,
                              index) in currentYearTotalProjectNumArr"
                              :key="index"
                              @click="jumpClass(true, false)"
                            >
                              <span v-if="!isNaN(item)">
                                <i ref="numberItem4">0123456789</i>
                              </span>
                              <span class="comma" v-else>{{ item }}</span>
                            </li>
                          </div>
                          <span
                            style="width:6rem;font-size:2rem;background: linear-gradient( 0deg, #ffffff 0%, #52ffff 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                            >个</span
                          >
                        </div>
                      </div>
                      <div>
                        <p
                          style="font-size:16px;color:#fff;padding-bottom:.65rem"
                        >
                          本年培训学员数量
                        </p>
                        <div style="display: flex;align-items: flex-end;">
                          <div class="box-item">
                            <li
                              :class="{
                                'number-item': !isNaN(item),
                                'mark-item': isNaN(item),
                              }"
                              v-for="(item,
                              index) in currentYearTotalProjectUserNumArr"
                              :key="index"
                              @click="jumpStudentList(true)"
                            >
                              <span v-if="!isNaN(item)">
                                <i ref="numberItem5">0123456789</i>
                              </span>
                              <span class="comma" v-else>{{ item }}</span>
                            </li>
                          </div>
                          <span
                            style="width:6rem;font-size:2rem;background: linear-gradient( 0deg, #ffffff 0%, #52ffff 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                            >人</span
                          >
                        </div>
                      </div>
                      <div>
                        <p
                          style="font-size:16px;color:#fff;padding-bottom:.65rem"
                        >
                          本年培训课时
                        </p>
                        <div style="display: flex;align-items: flex-end;">
                          <div class="box-item">
                            <li
                              :class="{
                                'number-item': !isNaN(item),
                                'mark-item': isNaN(item),
                              }"
                              v-for="(item,
                              index) in currentYearTotalProjectLessonNumArr"
                              :key="index"
                              @click="jumpstudylessnum(true)"
                            >
                              <span v-if="!isNaN(item)">
                                <i ref="numberItem6">0123456789</i>
                              </span>
                              <span class="comma" v-else>{{ item }}</span>
                            </li>
                          </div>
                          <span
                            style="width:6rem;font-size:2rem;background: linear-gradient( 0deg, #ffffff 0%, #52ffff 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                            >课时</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style="display:flex;flex-warp:warp;height: 100%;align-items: center;justify-content:center;"
                    class="largeLeft_container_center"
                    v-show="switchs == 3"
                  >
                    <div
                      style="display:flex;flex-direction:column;width: 100%;height:100%;align-items: center;justify-content:center;padding-bottom:1rem;"
                    >
                      <div>
                        <p
                          style="font-size:16px;color:#fff;padding-bottom:.65rem;"
                        >
                          培训中班级数量
                        </p>
                        <div style="display: flex;align-items: flex-end;">
                          <div class="box-item">
                            <li
                              :class="{
                                'number-item': !isNaN(item),
                                'mark-item': isNaN(item),
                              }"
                              v-for="(item,
                              index) in trainingTotalProjectNumArr"
                              :key="index"
                              @click="jumpClass(false, true)"
                            >
                              <span v-if="!isNaN(item)">
                                <i ref="numberItem7">0123456789</i>
                              </span>
                              <span class="comma" v-else>{{ item }}</span>
                            </li>
                          </div>
                          <span
                            style="width:6rem;font-size:2rem;background: linear-gradient( 0deg, #ffffff 0%, #52ffff 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                            >个</span
                          >
                        </div>
                      </div>
                      <div style="margin-top:2rem">
                        <p
                          style="font-size:16px;color:#fff;padding-bottom:.65rem"
                        >
                          培训中学员数量
                        </p>
                        <div style="display: flex;align-items: flex-end;">
                          <div class="box-item">
                            <li
                              :class="{
                                'number-item': !isNaN(item),
                                'mark-item': isNaN(item),
                              }"
                              v-for="(item,
                              index) in trainingTotalProjectUserNumArr"
                              :key="index"
                              @click="jumpStudentList(false, 1)"
                            >
                              <span v-if="!isNaN(item)">
                                <i ref="numberItem8">0123456789</i>
                              </span>
                              <span class="comma" v-else>{{ item }}</span>
                            </li>
                          </div>
                          <span
                            style="width:6rem;font-size:2rem;background: linear-gradient( 0deg, #ffffff 0%, #52ffff 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                            >人</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- </transition> -->
                </div>
              </div>
            </div>
            <div class="largeLeft_container">
              <div class="largeLeft_container_border">
                <div
                  style="display:flex;flex-direction: column;height: 100%; padding: 1rem 2.35rem 0px;"
                >
                  <h3 class="titleH3">精品课程</h3>
                  <div style="height:100%;display: flex;margin-top:2rem">
                    <player2
                      ref="player2"
                      v-if="aa"
                      :videoId="videoId"
                      :videoMutePlay="videoMutePlay"
                      @ended="ended"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="largebox" style="width:45%">
          <div class="largeLeft">
            <div class="largeLeft_container" style="height: 33%;">
              <div class="largeLeft_container_border">
                <div
                  style="display:flex;flex-direction: column;height: 100%; padding: 0 2.35rem;padding-bottom:1rem"
                >
                  <div>
                    <div style="display:flex;">
                      <h3 class="titleH3">
                        学习标兵
                      </h3>
                      <div
                        style="width:70%;padding-top: 1rem; margin-left:2rem"
                      >
                        <img
                          src="../../assets/linepc.png"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div class="goodtop">
                      <div class="imgBoxs">
                        <img src="../../assets/numberOne.png" alt="" />
                        <div>
                          <p>{{ userName1 }}</p>
                          <p>完成度:{{ completeProgressRate1 + "%" }}</p>
                        </div>
                      </div>
                      <div class="imgBoxs">
                        <img src="../../assets/numberTwo.png" alt="" />
                        <div>
                          <p>{{ userName2 }}</p>
                          <p>完成度:{{ completeProgressRate2 + "%" }}</p>
                        </div>
                      </div>
                      <div class="imgBoxs">
                        <img src="../../assets/numberThree.png" alt="" />
                        <div>
                          <p>{{ userName3 }}</p>
                          <p>完成度:{{ completeProgressRate3 + "%" }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style="display: flex;flex-direction: column;flex:1;height: 48%;overflow: hidden;"
                  >
                    <ul style="display:flex;" class="ulTitle">
                      <li>
                        <span style="max-width:50px">排名</span>
                        <span style="max-width:80px">姓名</span>
                        <span>班级名称</span>
                        <span style="max-width:80px">完成进度</span>
                        <span style="max-width:80px">完成学时</span>
                      </li>
                    </ul>
                    <div @click="clickProps($event)">
                      <vue-seamless-scroll
                        :data="modelStudentList"
                        class="seamless-warp"
                        :class-option="classOption3"
                      >
                        <ul
                          style="display:flex;flex-direction: column;"
                          class="ulTitle ulcontent"
                        >
                          <li
                            v-for="(item, index) in modelStudentList"
                            :class="{ on: index % 2 == 0, off: index % 2 != 0 }"
                            :key="index"
                          >
                            <span style="max-width:50px" :id="item.userId">{{
                              index + 1
                            }}</span>
                            <span style="max-width:80px" :id="item.userId">{{
                              item.userName
                            }}</span>
                            <span :id="item.userId">{{
                              item.projectName
                            }}</span>
                            <span style="max-width:80px" :id="item.userId">{{
                              item.completeProgressRate + "%"
                            }}</span>
                            <span style="max-width:80px" :id="item.userId">{{
                              item.completeLessonNum
                            }}</span>
                          </li>
                        </ul>
                      </vue-seamless-scroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="largeLeft_container">
              <div class="largeLeft_container_border">
                <div
                  class="largeLeftPiebox"
                  style="display:flex;flex-direction: column;"
                >
                  <div style="display:flex; padding-left:2.35rem">
                    <h3 class="titleH3">
                      学员结构
                    </h3>
                    <div style="width:70%;padding-top: 1rem; margin-left:2rem">
                      <img src="../../assets/linepc.png" alt="" width="100%" />
                    </div>
                  </div>
                  <div style="display:flex;height: 80%;">
                    <div class="largepie">
                      <div id="pieEchart2" style="width:100%;height:100%"></div>
                    </div>
                    <div class="largepie">
                      <div id="pieEchart3" style="width:100%;height:100%"></div>
                    </div>
                  </div>
                  <div style="height: 10%;padding-bottom: 0.5rem;">
                    <!-- <ul style="display:flex;height:100%" class="listul">
                      <li v-for="(item, index) in postList" :key="index">
                        <span
                          style="width:10px;height:10px;border-radius:50%;margin-right:5px"
                          :style="
                            item.postName == '三级教育培训'
                              ? 'background:#8f52ff;'
                              : item.postName == '年度安全再培训'
                              ? 'background:#FFAE00;'
                              : item.postName == '“四新”安全培训'
                              ? 'background:#F53F8C;'
                              : item.postName == '专项培训'
                              ? 'background:#FFFD52;'
                              : item.postName == '调岗和重新上岗培训'
                              ? 'background:#28B1FF;'
                              : 'background:#52FF97;'
                          "
                        ></span
                        >{{ item.postName }}
                      </li>
                    </ul> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="largeLeft_container" style="height:33%">
              <div class="largeLeft_container_border">
                <div
                  style="display:flex;flex-direction: column;height: 100%;padding: 0 2.35rem;padding-bottom: 1rem;"
                >
                  <div style="display:flex;">
                    <h3 class="titleH3">
                      培训学员
                    </h3>
                    <div style="width:70%;padding-top: 1rem; margin-left:2rem">
                      <img src="../../assets/linepc.png" alt="" width="100%" />
                    </div>
                  </div>
                  <div
                    style="display: flex;flex-direction: column;flex:1;height: 48%;overflow: hidden;"
                  >
                    <ul style="display:flex;" class="ulTitle">
                      <li
                        class="projectNum"
                        style="border-bottom: 0.05rem solid #024058"
                      >
                        <span style="width:4rem">姓名</span>
                        <span style="width:4rem">手机号</span>
                        <span>班级名称</span>
                        <span>培训进度</span>
                      </li>
                    </ul>
                    <div @click="clickProps($event)">
                      <vue-seamless-scroll
                        :data="TrainstudentList"
                        class="seamless-warp"
                        :class-option="classOption5"
                      >
                        <ul
                          style="display:flex;flex-direction: column"
                          class="ulTitle projectNumcontent"
                        >
                          <li
                            v-for="(item, index) in TrainstudentList"
                            style="border-bottom: 0.05rem solid #024058"
                            :key="index"
                          >
                            <span
                              class="studentspan"
                              style="width:4rem"
                              :id="item.userId"
                              >{{ item.userName }}</span
                            >
                            <span
                              class="studentspan"
                              style="width:4rem"
                              :id="item.userId"
                              >{{ item.mobile }}</span
                            >
                            <span class="studentspan" :id="item.userId">{{
                              item.projectName
                            }}</span>
                            <span :id="item.userId">{{
                              item.progress + "%"
                            }}</span>
                          </li>
                        </ul>
                      </vue-seamless-scroll>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <el-dialog
        class="dialog2"
        :visible.sync="dialog1"
        width="50%"
        top="15%"
        center
        ><div class="studentBox">
          <div
            style="width:10rem;height:3rem;    position: relative;left: 4rem;top: .5rem;"
          >
            <img
              :src="ruleForm.headPathUrl || require('@/assets/headpath.png')"
              alt=""
              style="width:3rem;height:3rem;border-radius:50%"
            />
          </div>
          <div style="width:100%">
            <el-form :inline="true" ref="ruleForm" label-position="left">
              <el-form-item label="姓名:" class="form-inline">
                <p>{{ ruleForm.userName || "--" }}</p>
              </el-form-item>
              <el-form-item label="性别:" class="form-inline">
                <p>
                  {{
                    ruleForm.sex == "1"
                      ? "男"
                      : ruleForm.sex == "0"
                      ? "女"
                      : "--"
                  }}
                </p>
              </el-form-item>
              <el-form-item label="年龄:" class="form-inline">
                <p>{{ ruleForm.age || "--" }}</p>
              </el-form-item>
              <el-form-item label="学历:" class="form-inline">
                <p>{{ ruleForm.education || "--" }}</p>
              </el-form-item>
              <el-form-item label="专业:" class="form-inline">
                <p>{{ ruleForm.profession || "--" }}</p>
              </el-form-item>
              <el-form-item label="职务/职称:" class="form-inline">
                <p>{{ ruleForm.professionTitle || "--" }}</p>
              </el-form-item>
              <el-form-item label="人员类型:" class="form-inline">
                <p>{{ ruleForm.userType || "--" }}</p>
              </el-form-item>
              <el-form-item label="部门:" class="form-inline">
                <p>{{ ruleForm.department || "--" }}</p>
              </el-form-item>
              <el-form-item label="工种:" class="form-inline">
                <p>{{ ruleForm.workType || "--" }}</p>
              </el-form-item>
              <el-form-item label="联系电话:" class="form-inline">
                <p>{{ ruleForm.mobile || "--" }}</p>
              </el-form-item>
              <el-form-item label="身份证号:" class="form-inline">
                <p>{{ ruleForm.idcard || "--" }}</p>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="height:350px;padding: 2rem;">
          <div style="display: flex;flex-direction: column;flex:1;height:100%">
            <ul style="display:flex;" class="ulTitle">
              <li
                class="projectNum"
                style="border-bottom: 0.05rem solid #024058"
              >
                <span style="max-width:9rem">培训时间</span>
                <span>培训班级</span>
                <span>岗位名称</span>
                <span style="max-width:4.5rem">培训学时</span>
                <span style="max-width:4.5rem">培训结果</span>
                <span style="max-width:4.5rem">学员档案</span>
              </li>
            </ul>
            <vue-seamless-scroll
              :data="TrainProjectList"
              class="seamless-warp"
              :class-option="classOption4"
            >
              <ul
                style="display:flex;flex-direction: column"
                class="ulTitle projectNumcontent"
              >
                <li
                  v-for="(item, index) in ruleForm.list"
                  style="border-bottom: 0.05rem solid #024058"
                  :key="index"
                >
                  <span style="max-width:9rem"
                    >{{ item.startDate }} - {{ item.endDate }}</span
                  >
                  <span>{{ item.projectName }}</span>
                  <span>{{ item.postName }}</span>
                  <span style="max-width:4.5rem">{{
                    item.studyLessonNumMust
                  }}</span>
                  <span style="max-width:4.5rem">{{
                    item.studyState == "20" || item.studyState == "10"
                      ? "培训中"
                      : item.studyState == "30"
                      ? "已结业"
                      : item.studyState == "40"
                      ? "未结业"
                      : ""
                  }}</span>
                  <span style="max-width:4.5rem"
                    ><el-button
                      type="text"
                      @click="see(item)"
                      :disabled="item.state != '30'"
                      >预览档案</el-button
                    ></span
                  >
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="预览档案"
        :visible.sync="dialogDownload"
        top="1%"
        width="50%"
        class="dialogpdf"
        center
        ><div id="pdf-content" style="height: 800px"></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
// import PageNum from "@/components/PageNum.vue";
// import countTo from "vue-count-to";
import player2 from "@/components/player2";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
import scroll from "vue-seamless-scroll";
import pdf from "pdfobject";
// let echart1 = {};
let pieEchart = {};
let pieEchart1 = {};
let pieEchart2 = {};
let pieEchart3 = {};
export default {
  name: "OneEnterpriseOneFile",
  components: {
    player2,
    // countTo,
    // Empty,
    // PageNum,
  },
  mixins: [List],
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 56, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: this.listAnimation == "20" ? 330 : 33,
        waitTime: this.listLoopSpace * 1000,
      };
    },
    classOption5() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 56, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: this.listAnimation == "20" ? 330 : 33,
        waitTime: this.listLoopSpace * 1000,
      };
    },
    classOption2() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 56, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: this.listAnimation == "20" ? 330 : 33,
        waitTime: this.listLoopSpace * 1000,
      };
    },
    classOption3() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 56, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: this.listAnimation == "20" ? 330 : 33,
        waitTime: this.listLoopSpace * 1000,
      };
    },
    classOption4() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 56, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: this.listAnimation == "20" ? 330 : 33,
        waitTime: this.listLoopSpace * 1000,
      };
    },
  },

  data() {
    return {
      percentage: 0,
      aa: false,
      dialogDownload: false,
      duration: 1500,
      startVal: 0,
      totalProjectNum: 0, //累计开班数量
      totalProjectUserNum: 0, //累计培训学员数量
      totalProjectLessonNum: 0, //累计培训课时
      currentYearTotalProjectNum: 0, //本年开班数量
      currentYearTotalProjectUserNum: 0, //本年培训学员数量
      currentYearTotalProjectLessonNum: 0, //本年培训课时
      trainingTotalProjectNum: 0, //培训中开班数量
      trainingTotalProjectUserNum: 0, //培训中学员数量
      switchStatus: "1",
      dialog1: false,
      ruleForm: {},
      TrainProjectList: [], //培训班级
      TrainstudentList: [], //培训班级
      modelStudentList: [],
      userName1: "",
      completeProgressRate1: "",
      userName2: "",
      completeProgressRate2: "",
      userName3: "",
      completeProgressRate3: "",
      orderNum: ["0", "0", "0", "0", "0", "0"],
      totalProjectNumArr: ["0", "0", "0", "0", "0", "0"],
      totalProjectUserNumArr: ["0", "0", "0", "0", "0", "0"],
      totalProjectLessonNumArr: ["0", "0", "0", "0", "0", "0"],
      currentYearTotalProjectNumArr: ["0", "0", "0", "0", "0", "0"],
      currentYearTotalProjectUserNumArr: ["0", "0", "0", "0", "0", "0"],
      currentYearTotalProjectLessonNumArr: ["0", "0", "0", "0", "0", "0"],
      trainingTotalProjectNumArr: ["0", "0", "0", "0", "0", "0"],
      trainingTotalProjectUserNumArr: ["0", "0", "0", "0", "0", "0"],
      switchShow: true,
      switchShow1: false,
      switchShow2: false,
      switchs: 1,
      postList: [],
      nowDate: null, //存放年月日变量
      nowTime: null, //存放时分秒变量
      timer: "", //定义一个定时器的变量
      timer2: "", //定义一个定时器的变量
      currentTime: new Date(), // 获取当前时间
      videoId: "",
      projessList: [],
      listLoopSpace: 0,
      listAnimation: "20",
      videoInd: 0,
      videos: [],
      compName: "",
      videoMutePlay: false,
      percentagebool: true,
      timeObj: 0,
    };
  },
  created() {
    // let timeprogress = setInterval(() => {
    //   let aa = parseInt(15 + Math.random() * 10);
    //   if (this.percentage + aa >= 100) {
    //     if (!this.percentagebool) {
    //       this.percentage = 100;
    //       setTimeout(() => {
    //         this.percentagebool = true;
    //       },300)

    //       return;
    //     }
    //   }
    //   this.percentage += aa;
    //   // this.percentage += this.percentage +1
    //   if (this.percentage > 100) {
    //     clearInterval(timeprogress);

    //   }
    // }, 500);
    // this.init()
    setTimeout(() => {
      this.getNumCount();
    }, 600000);
  },
  mounted() {},
  watch: {
    timeObj: {
      handler(val) {
        this.percentage += 20;
        // let aa = parseInt(15 + Math.random() * 10);
        if (val >= 5) {
          setTimeout(() => {
            this.percentagebool = false;
          }, 2000);
          // this.percentage = 0;

          //     console.log(val)
          //      if (this.percentage + aa >= 100) {
          //   if (!this.percentagebool) {
          //     this.percentage = 100;
          //       this.percentagebool = true;

          //     return;
          //   }
          // }
          //  this.percentage += aa;
        }
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.getName();
      this.timer = setInterval(this.getTime, 1000);
      this.getClassproress();
      this.getPostTrainDataAnalyse();
      this.getClassNum();
      this.getNumCount();
      this.getqueryTrainStudent();
      this.getmodelStudent();
      this.getCollegeStructure();
      this.switchsall(); //
      this.getVideo();
    },
    getTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      const wk = date.getDay();
      this.month = check(month);
      this.day = check(day);
      this.hour = check(hour);
      this.minute = check(minute);
      this.second = check(second);
      function check(i) {
        const num = i < 10 ? "0" + i : i;
        return num;
      }
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let week = weeks[wk];
      this.nowDate = year + "年" + this.month + "月" + this.day + "日";
      this.nowTime = this.hour + ":" + this.minute + ":" + this.second;
      this.nowWeek = week;
    },
    getName() {
      this.$post("/sys/company/admin/comp")
        .then((ret) => {
          this.compName = ret.data.compName;
        })
        .catch((err) => {
          return;
        });
    },
    //培训班级进度
    getClassproress() {
      this.$post("/biz/large/screen/display/projectLearningProgress", {})
        .then((ret) => {
          this.projessList = ret.data;
          // this.initEchart(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    clickProps1(e) {
      let item = JSON.parse(e.target.dataset.obj);
      this.jumpStudent(item.projectName, e.target.id);
    },
    jumpStudent(projectName, projectState) {
      this.$router.push({
        path: "/web/largeScreenstudentList",
        query: {
          projectName,
          projectState,
        },
      });
    },
    //岗位培训数据分析
    getPostTrainDataAnalyse() {
      this.$post("/biz/large/screen/display/postTrainDataAnalyse", {})
        .then((ret) => {
          this.postList = ret.data.postList || [];
          this.initEchartpie(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    //学员结构
    getCollegeStructure() {
      this.$post("/biz/large/screen/display/collegeStructure")
        .then((ret) => {
          this.initEchartpie2(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    //培训班级
    getClassNum() {
      this.$post("/biz/large/screen/display/queryTrainProject", {})
        .then((ret) => {
          this.TrainProjectList = ret.data || [];
          this.timeObj += 1;
        })
        .catch((err) => {
          this.timeObj += 1;
          return;
        });
    },
    //培训学员
    getqueryTrainStudent() {
      this.$post("/biz/large/screen/display/queryTrainStudent", {})
        .then((ret) => {
          this.TrainstudentList = ret.data || [];
          this.timeObj += 1;
        })
        .catch((err) => {
          this.timeObj += 1;
          return;
        });
    },
    //学习标兵
    getmodelStudent() {
      this.$post("/biz/large/screen/display/queryVanguardList")
        .then((ret) => {
          this.modelStudentList = ret.data || [];
          this.userName1 = ret.data[0].userName;
          this.completeProgressRate1 = ret.data[0].completeProgressRate;
          this.userName2 = ret.data[1].userName;
          this.completeProgressRate2 = ret.data[1].completeProgressRate;
          this.userName3 = ret.data[2].userName;
          this.completeProgressRate3 = ret.data[2].completeProgressRate;
          this.timeObj += 1;
        })
        .catch((err) => {
          this.timeObj += 1;
          return;
        });
    },
    //数据汇总
    getNumCount() {
      this.$post("/biz/large/screen/display/queryDataStatistic")
        .then((ret) => {
          // this.ProjectNum = ret.data || []
          this.totalProjectNum = ret.data.totalProjectNum; //累计开班数量
          this.totalProjectUserNum = ret.data.totalProjectUserNum; //累计培训学员数量
          this.totalProjectLessonNum = ret.data.totalProjectLessonNum; //累计培训课时
          this.currentYearTotalProjectNum = ret.data.currentYearTotalProjectNum; //本年开班数量
          this.currentYearTotalProjectUserNum =
            ret.data.currentYearTotalProjectUserNum; //本年培训学员数量
          this.currentYearTotalProjectLessonNum =
            ret.data.currentYearTotalProjectLessonNum; //本年培训课时
          this.trainingTotalProjectNum = ret.data.trainingTotalProjectNum; //培训中开班数量
          this.trainingTotalProjectUserNum =
            ret.data.trainingTotalProjectUserNum; //培训中学员数量
          this.toOrderNum(this.totalProjectNum, "1");
          this.toOrderNum(this.totalProjectUserNum, "2");
          this.toOrderNum(this.totalProjectLessonNum, "3");
          this.toOrderNum(this.currentYearTotalProjectNum, "4");
          this.toOrderNum(this.currentYearTotalProjectUserNum, "5");
          this.toOrderNum(this.currentYearTotalProjectLessonNum, "6");
          this.toOrderNum(this.trainingTotalProjectNum, "7");
          this.toOrderNum(this.trainingTotalProjectUserNum, "8");
          this.setNumberTransform();
          this.timeObj += 1;
        })
        .catch((err) => {
          this.timeObj += 1;
          return;
        });
    },
    //累计班级
    jumpClass(currentYear, traing) {
      this.$router.push({
        path: "/web/largeScreenclassDetails",
        query: {
          currentYear,
          traing,
        },
      });
    },
    //累计学员
    jumpStudentList(currentYear, projectState) {
      this.$router.push({
        path: "/web/largeScreenstudentList",
        query: {
          currentYear,
          projectState,
        },
      });
    },
    SummarySwitch(state) {
      this.switchs = state;
    },
    jumpstudylessnum(currentYear) {
      this.$router.push({
        path: "/web/largeScreentrainingCourse",
        query: {
          currentYear,
        },
      });
    },
    switchsall() {
      console.log(this.switchs);
      this.timer2 = setInterval(() => {
        this.switchs++;
        if (this.switchs == 4) {
          this.switchs = 1;
        }
      }, 5000);
    },
    clickProps(e) {
      if (e.target.id) {
        this.studentInfo(e.target.id);
      }
    },
    studentInfo(userId) {
      this.dialog1 = true;
      this.$post("/largescreen/ls-config/studentDetail", { userId })
        .then((ret) => {
          this.ruleForm = {
            ...ret.data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    /* 查看学习记录 */
    see(item) {
      let parmar = {
        projectId: item.projectId,
        userId: item.userId,
      };
      this.$post(
        "/eighteenEnterprise/enterpriseUser/downloadUserDocument",
        parmar
      ).then((res) => {
        if (res.status == 0) {
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    // see(item) {
    //   let parmar = {
    //     projectId: item.projectId,
    //     userId: item.userId,
    //   };
    //   this.$post("/cert/downloadTrainDocument", parmar).then((res) => {
    //     if (res.status == 0) {
    //       this.dialogDownload = true;
    //       this.$nextTick(() => {
    //         pdf.embed(res.data, "#pdf-content");
    //       });

    //     } else {
    //       that.$message.warning({ message: res.message, duration: 1500 });
    //     }
    //   });
    // },
    getVideo() {
      this.$post("/largescreen/ls-config/query", {})
        .then((ret) => {
          this.listLoopSpace = ret.data.listLoopSpace;
          this.listAnimation = ret.data.listAnimation;
          this.videoMutePlay = ret.data.videoMutePlay;
          this.videos = ret.data.videos;
          this.showVideo(this.videos[this.videoInd].kpointKey);
          this.timeObj += 1;
        })
        .catch((err) => {
          this.timeObj += 1;
          return;
        });
    },
    showVideo(videoId) {
      this.videoId = videoId;
      this.aa = true;
    },
    ended() {
      this.$refs.player2.dd();
      if (this.videoInd == this.videos.length - 1) {
        this.videoInd = 0;
        this.showVideo(this.videos[this.videoInd].kpointKey);
      } else {
        this.videoInd++;
        this.showVideo(this.videos[this.videoInd].kpointKey);
      }
      setTimeout(() => {
        this.$refs.player2.start();
      }, 2000);
    },
    initEchartpie(parmar) {
      const that = this;
      console.log(parmar);
      pieEchart = that.$echarts.init(document.getElementById("pieEchart"));
      pieEchart1 = that.$echarts.init(document.getElementById("pieEchart1"));
      let projectNum = [];
      let studentNum = [];
      let postName = [];
      let color = [];
      let color2 = [];
      for (let i in parmar.projectList) {
        projectNum.push({
          value: parmar.projectList[i].number,
          name: parmar.projectList[i].postName,
          postId: parmar.projectList[i].postId,
        });
      }
      for (let i in parmar.postList) {
        postName.push(parmar.projectList[i].postName);
        if (parmar.projectList[i].postId == 5) {
          color.push("#8f52ff");
        }
        if (parmar.projectList[i].postId == 6) {
          color.push("#FFAE00");
        }
        if (parmar.projectList[i].postId == 8) {
          color.push("#FFFD52");
        }
        if (parmar.projectList[i].postId == 10) {
          color.push("#52FF97");
        }
        if (parmar.projectList[i].postId == 7) {
          color.push("#F53F8C");
        }
        if (parmar.projectList[i].postId == 9) {
          color.push("#28B1FF");
        }
      }
      for (let i in parmar.studentList) {
        studentNum.push({
          value: parmar.studentList[i].number,
          name: parmar.studentList[i].postName,
          postId: parmar.studentList[i].postId,
        });
      }
      for (let i in parmar.studentList) {
        if (parmar.studentList[i].postId == 5) {
          color2.push("#8f52ff");
        }
        if (parmar.studentList[i].postId == 6) {
          color2.push("#FFAE00");
        }
        if (parmar.studentList[i].postId == 8) {
          color2.push("#FFFD52");
        }
        if (parmar.studentList[i].postId == 10) {
          color2.push("#52FF97");
        }
        if (parmar.studentList[i].postId == 7) {
          color2.push("#F53F8C");
        }
        if (parmar.studentList[i].postId == 9) {
          color2.push("#28B1FF");
        }
      }
      const option = {
        title: {
          text: "开设班级\n占比",
          x: "center",
          y: "center",
          textStyle: {
            fontSize: 13,
            color: "#fff",
          },
        },
        color: color,
        series: [
          {
            name: "",
            type: "pie",
            radius: ["60%", "40%"],
            minAngle: 30, // 设置每块扇形的最小占比
            hoverAnimation: false,
            label: {
              normal: {
                show: true,
                formatter: "{d}%",
              },
            },
            labelLine: {
              normal: {
                length: 20,
              },
            },
            data: projectNum,
            center: "49%",
          },
        ],
      };
      const option1 = {
        title: {
          text: "培训学员\n占比",
          x: "center",
          y: "center",
          textStyle: {
            fontSize: 13,
            color: "#fff",
          },
        },

        color: color2,
        // grid: {
        //   left: "5%", //图表距边框的距离
        //   right: "4%",
        //   bottom: "2%",
        //   containLabel: true,
        // },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["60%", "40%"],
            minAngle: 30, // 设置每块扇形的最小占比
            hoverAnimation: false,
            label: {
              normal: {
                show: true,
                formatter: "{d}%",
              },
            },
            labelLine: {
              normal: {
                length: 20,
              },
            },
            // hoverAnimation: false,
            // label: {
            //   formatter: "{d}%",
            //   padding: [40, -10],
            //   show: true,
            // },
            // labelLine: {
            //   normal: {
            //     show: true,
            //     color: "pink",
            //     shadowBlur: "800",
            //     shadowColor: "rgba(225,0,241,1)",
            //     shadowOffsetY: "150",
            //   },
            // },
            data: studentNum,
            center: "49%",
          },
        ],
      };

      pieEchart.setOption(option);
      pieEchart1.setOption(option1);
      pieEchart.on("click", function(param) {
        let path = "/web/largeScreenclassDetails";
        let parmar = {
          params: param.data.postId,
        };
        let params = param.data.name;
        that.doLook(path, parmar);
        // that.doLook(path, params, areaId);
      });
      pieEchart1.on("click", function(param) {
        let path = "/web/largeScreenstudentList";
        let parmar = {
          params: param.data.postId,
          projectState: 2,
          jumpType: "3",
        };
        let params = param.data.name;
        that.doLook(path, parmar);
      });
      window.addEventListener("resize", function() {
        pieEchart.resize();
        pieEchart1.resize();
      });
    },
    initEchartpie2(parmar) {
      const that = this;
      pieEchart2 = that.$echarts.init(document.getElementById("pieEchart2"));
      console.log(pieEchart2);
      pieEchart3 = that.$echarts.init(document.getElementById("pieEchart3"));
      //  document
      //   .getElementById("echart1")
      //   .removeAttribute("_echarts_instance_");
      const option2 = {
        title: {
          text: "年龄\n区间",
          x: "center",
          y: "center",
          textStyle: {
            fontSize: 17,
            color: "#fff",
          },
        },
        color: [
          "#52FFFF",
          "#FFAE00",
          "#F53F8C",
          "#FFFD52",
          "#28B1FF",
          "#52FF97",
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["60%", "40%"],
            minAngle: 25, // 设置每块扇形的最小占比
            hoverAnimation: false,
            label: {
              normal: {
                show: true,
                formatter: "{b}\n{c}人",
              },
            },
            labelLine: {
              normal: {
                length: 20,
              },
            },
            data: parmar.ageRange,
            center: "49%",
          },
        ],
      };
      const option3 = {
        title: {
          text: "学历\n结构",
          x: "center",
          y: "center",
          textStyle: {
            fontSize: 17,
            color: "#fff",
          },
        },
        color: [
          "#52FFFF",
          "#FFAE00",
          "#F53F8C",
          "#FFFD52",
          "#28B1FF",
          "#52FF97",
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["60%", "40%"],
            // center: ['10%', '40%'],
            // radius: [40, 80],
            // center: ["25%", "50%"],
            minAngle: 30, // 设置每块扇形的最小占比
            // roseType: "radius",
            hoverAnimation: false,
            label: {
              normal: {
                show: true,
                formatter: "{b}\n{c}人",
              },
            },
            labelLine: {
              normal: {
                length: 20,
              },
            },
            data: parmar.educationRange,
          },
        ],
      };

      pieEchart2.setOption(option2);
      pieEchart3.setOption(option3);
      pieEchart2.on("click", function(param) {
        console.log(param);
        let path = "/web/largeScreenstudentList";
        let parmar = {
          params:
            param.data.name == "35-45"
              ? "2"
              : param.name == "45-55"
              ? "3"
              : param.name == "55岁以上"
              ? "4"
              : param.name == "35岁以下"
              ? "1"
              : "",
          jumpType: "1",
          projectState: 3,
        };
        let params = param.data.name;
        that.doLook(path, parmar);
      });
      pieEchart3.on("click", function(param) {
        let parmar = {
          params: param.data.type,
          projectState: 3,
          jumpType: "2",
        };
        let path = "/web/largeScreenstudentList";
        let params = param.data.type;
        that.doLook(path, parmar);
      });
      window.addEventListener("resize", function() {
        pieEchart2.resize();
        pieEchart3.resize();
      });
    },
    doLook(url, parmar) {
      this.$router.push({
        path: url,
        query: {
          parmar,
        },
      });
    },
    //     increaseNumber () {
    //  let self = this
    //  this.timer = setInterval(() => {
    //  self.newNumber = self.newNumber + getRandomNumber(1, 100)
    //  self.setNumberTransform()
    //  }, 3000)
    // },
    // 设置文字滚动
    setNumberTransform() {
      const numberItems1 = this.$refs.numberItem1; // 拿到数字的ref，计算元素数量
      const numberItems2 = this.$refs.numberItem2; // 拿到数字的ref，计算元素数量
      const numberItems3 = this.$refs.numberItem3; // 拿到数字的ref，计算元素数量
      const numberItems4 = this.$refs.numberItem4; // 拿到数字的ref，计算元素数量
      const numberItems5 = this.$refs.numberItem5; // 拿到数字的ref，计算元素数量
      const numberItems6 = this.$refs.numberItem6; // 拿到数字的ref，计算元素数量
      const numberItems7 = this.$refs.numberItem7; // 拿到数字的ref，计算元素数量
      const numberItems8 = this.$refs.numberItem8; // 拿到数字的ref，计算元素数量
      const numberArr1 = this.totalProjectNumArr.filter((item) => !isNaN(item));
      const numberArr2 = this.totalProjectUserNumArr.filter(
        (item) => !isNaN(item)
      );
      const numberArr3 = this.totalProjectLessonNumArr.filter(
        (item) => !isNaN(item)
      );
      const numberArr4 = this.currentYearTotalProjectNumArr.filter(
        (item) => !isNaN(item)
      );
      const numberArr5 = this.currentYearTotalProjectUserNumArr.filter(
        (item) => !isNaN(item)
      );
      const numberArr6 = this.currentYearTotalProjectLessonNumArr.filter(
        (item) => !isNaN(item)
      );
      const numberArr7 = this.trainingTotalProjectNumArr.filter(
        (item) => !isNaN(item)
      );
      const numberArr8 = this.trainingTotalProjectUserNumArr.filter(
        (item) => !isNaN(item)
      );

      // 结合CSS 对数字字符进行滚动,显示订单数量
      for (let index = 0; index < numberItems1.length; index++) {
        const elem = numberItems1[index];
        elem.style.transform = `translate(-50%, -${numberArr1[index] * 10}%)`;
      }
      for (let index = 0; index < numberItems2.length; index++) {
        const elem = numberItems2[index];
        elem.style.transform = `translate(-50%, -${numberArr2[index] * 10}%)`;
      }
      for (let index = 0; index < numberItems3.length; index++) {
        const elem = numberItems3[index];
        elem.style.transform = `translate(-50%, -${numberArr3[index] * 10}%)`;
      }
      for (let index = 0; index < numberItems4.length; index++) {
        const elem = numberItems4[index];
        elem.style.transform = `translate(-50%, -${numberArr4[index] * 10}%)`;
      }
      for (let index = 0; index < numberItems5.length; index++) {
        const elem = numberItems5[index];
        elem.style.transform = `translate(-50%, -${numberArr5[index] * 10}%)`;
      }
      for (let index = 0; index < numberItems6.length; index++) {
        const elem = numberItems6[index];
        elem.style.transform = `translate(-50%, -${numberArr6[index] * 10}%)`;
      }
      for (let index = 0; index < numberItems7.length; index++) {
        const elem = numberItems7[index];
        elem.style.transform = `translate(-50%, -${numberArr7[index] * 10}%)`;
      }
      for (let index = 0; index < numberItems8.length; index++) {
        const elem = numberItems8[index];
        elem.style.transform = `translate(-50%, -${numberArr8[index] * 10}%)`;
      }
    },
    toOrderNum(num, stu) {
      // num = num.toString();
      // 把订单数变成字符串
      if (num.length < 6) {
        num = "0" + num; // 如未满六位数，添加"0"补位
        this.toOrderNum(num, stu); // 递归添加"0"补位
      } else if (num.length === 6) {
        if (stu == "1") {
          this.totalProjectNumArr = num.split(""); // 将其便变成数据，渲染至滚动数组
        }
        if (stu == "2") {
          this.totalProjectUserNumArr = num.split(""); // 将其便变成数据，渲染至滚动数组
        }
        if (stu == "3") {
          this.totalProjectLessonNumArr = num.split(""); // 将其便变成数据，渲染至滚动数组
        }
        if (stu == "4") {
          this.currentYearTotalProjectNumArr = num.split(""); // 将其便变成数据，渲染至滚动数组
        }
        if (stu == "5") {
          this.currentYearTotalProjectUserNumArr = num.split(""); // 将其便变成数据，渲染至滚动数组
        }
        if (stu == "6") {
          this.currentYearTotalProjectLessonNumArr = num.split(""); // 将其便变成数据，渲染至滚动数组
        }
        if (stu == "7") {
          this.trainingTotalProjectNumArr = num.split(""); // 将其便变成数据，渲染至滚动数组
        }
        if (stu == "8") {
          this.trainingTotalProjectUserNumArr = num.split(""); // 将其便变成数据，渲染至滚动数组
        }
        // 订单数中加入逗号
        // num = num.slice(0, 1) + "," + num.slice(1, 3) + "," + num.slice(4, 5);
        // num = num/1000;
      } else {
        // 订单总量数字超过八位显示异常
        this.$message.warning("人数过大，显示异常，请联系客服");
      }
    },
    mouseOver() {
      this.$route.meta.show = false;
    },
  },
};
</script>
<style lang="less" scoped>
header {
  height: 9%;
  color: #fff;
}
.header {
  display: flex;
  align-items: center;
  height: 100%;
  .weekBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h2 {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
}
.sectionBox {
  display: flex;
  height: 91%;
  .largebox {
    width: 50%;
    height: 100%;
    .largeLeft {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .largeLeft_container {
        background-image: url("../../assets/borderbg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 50%;
        box-sizing: border-box;
        padding: 0.25rem;
        .largeLeft_container_border {
          // border: 0.2rem solid #3e3e3e;

          height: 100%;
          .largeLeftPiebox {
            width: 100%;
            height: 100%;
            display: flex;
            .largepie {
              width: 100%;
              height: 100%;
            }
          }
          .largeRight_container {
            width: 100%;
            height: 100%;
            .largeRight_container_border {
              width: 50%;
              height: 100%;
              border: 0.2rem solid red;
              .largeRight_container_studentNumBox {
                display: flex;
                .largeRight_container_studentNum {
                  width: 100%;
                  padding: 0.2rem;
                  > div {
                    border: 0.05rem solid #ccc;
                  }
                }
              }
              .largeRight_container_piechartBox {
                height: 80%;
                display: flex;
                flex-direction: column;
                .largeRight_container_piechart {
                  height: 100%;
                }
              }
            }
          }
          .largeLeft_container_center {
            flex-wrap: wrap;
            > .largeCenter_container_num {
              width: 33.3%;
              > div {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
              }
              p {
                text-align: center;
              }
              i {
                width: 2rem;
                height: 3rem;
                border: 1px solid red;
                font-size: 24px;
                line-height: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0.2rem;
              }
              em {
                min-width: 4rem;
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}
.ulTitle {
  font-size: 1rem;
  // padding: 0 2.35rem;
  li {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    span {
      text-align: center;
      flex: 1;
      padding: 0.5rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 100%;
    }
  }
  .projectNum {
    span {
      color: #52ffff;
    }
  }
}
.ulcontent {
  font-size: 0.9rem;
}
.projectNumcontent {
  font-size: 0.8rem;
  color: #ffffff;
}
.el-carousel {
  padding: 0 1rem;
}
.seamless-warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 0 1rem 0;
}
.studentBox {
  display: flex;
  padding-top: 2rem;
}
.el-form-item {
  width: 30%;
  margin-bottom: 0;
  height: 1.5rem;
  p {
    color: #fff;
  }
}
.on {
  background: transparent;
}
.off {
  background: #141e38;
}
.pageContol {
}
.bgc {
  background-image: url("../../assets/BG.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.titleH3 {
  padding-top: 1.3rem;
  font-weight: bold;
  background: linear-gradient(0deg, #76c0ff 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.goodtop {
  display: flex;
  .imgBoxs {
    width: 33%;
    position: relative;
    img {
      width: 100%;
    }
    div {
      position: absolute;
      top: 22%;
      left: 48%;
      p {
        color: #fff;
        font-size: 0.9rem;
      }
      p:nth-child(2) {
        font-size: 0.7rem;
      }
    }
  }
}
.btns {
  padding-top: 1.3rem;
  display: flex;
  span {
    margin-right: 0.75rem;
    width: 5rem;
    height: 2rem;
    color: #ccc;
    display: flex;
    background: #000000;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .btnbg {
    color: #fff;
    background: rgba(8, 9, 36, 0);
    box-shadow: 0px 0px 88px 7px rgba(16, 108, 222, 0.22),
      0px 0px 11px 3px rgba(0, 114, 255, 0.8);
    border-radius: 10px;
  }
}
.listul {
  flex-wrap: wrap;
  li {
    width: 33.3%;
    color: #fff;
  }
}
</style>
<style lang="less" scope>
.largeScreen {
  /*订单总量滚动数字设置*/
  .box-item {
    position: relative;
    font-size: 54px;
    line-height: 3.8rem;
    text-align: center;
    list-style: none;
    writing-mode: vertical-lr;
    text-orientation: upright;
    /*文字禁止编辑*/
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
    /* overflow: hidden; */
  }
  /* 默认逗号设置 */
  .mark-item {
    width: 10px;
    height: 100px;
    margin-right: 5px;
    line-height: 10px;
    font-size: 48px;
    position: relative;
    & > span {
      position: absolute;
      width: 100%;
      bottom: 0;
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }

  .number-item:last-child {
    margin-right: 0;
  }
  // .number-item {

  //   & > span {
  //     position: relative;
  //     display: inline-block;
  //     margin-right: 10px;
  //     width: 100%;
  //     height: 100%;
  //     writing-mode: vertical-rl;
  //     text-orientation: upright;
  //     overflow: hidden;
  //     & > i {
  //       position: absolute;
  //       top: 0;
  //       left: 50%;
  //       transform: translate(-50%, 0);
  //       transition: transform 0.5s ease-in-out;
  //       letter-spacing: 10px;
  //     }
  //   }
  // }
  /*滚动数字设置*/
  .number-item {
    width: 2.8rem;
    height: 3.8rem;
    background: #ccc;
    list-style: none;
    margin-right: 5px;
    border-radius: 4px;
    background: url(../../assets/number-bg.png) no-repeat center center;
    background-size: 100% 100%;

    & > span {
      position: relative;
      display: inline-block;
      margin-right: 10px;
      width: 100%;
      height: 100%;
      writing-mode: vertical-rl;
      text-orientation: upright;
      overflow: hidden;
      & > i {
        font-style: normal;
        position: absolute;
        top: 0;
        left: 50%;
        font-weight: 600;
        text-shadow: 0px 0px 13px rgba(7, 6, 28, 0.52);
        color: #52ffff;
        transform: translate(-50%, 0);
        transition: transform 1s ease-in-out;
        letter-spacing: 10px;
      }
    }
  }
  .el-dialog {
    background: transparent;
  }
  .el-dialog__header {
    display: none;
  }
  .dialog2 {
    .el-dialog__body {
      background-image: url("../../assets/dialogbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 0;
    }
  }

  .studentspan {
    width: 4rem;
  }
  .dialogpdf {
    .el-dialog__body {
      background-image: url("../../assets/dialogbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
     padding: 40px 20px 34px;
    }
  }
}
</style>
