<template>
  <div style="width: 100%; height: 80%; position: relative">
    <div class="prism-player" id="J_prismPlayer"></div>
  </div>
</template>

<script>
// import { createSwigger, distorySwigger } from "../utils/swigger";
import { mapGetters } from "vuex";
export default {
  props: {
    videoId: {
      type: String,
      default: "",
    },
    videoMutePlay: {
      type: Boolean,
      default: true,
    },
    courseId: {
      type: String,
      default: "",
    },
    kpointId: {
      type: String,
      default: "",
    },
    fullname: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player: null,
      currentTimes: null,
      Times: 0
    };
  },
  mounted() {
    this.start();
    
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    start() {
      let that = this;
      //查询节点信息
      that
        .$post("/sys/video/videoPlayAuth", { videoId: that.videoId })
        .then((result1) => {
          if (result1.status === "0") {
            setTimeout(() => {
              that.player = new Aliplayer(
                {
                  id: "J_prismPlayer",
                  width: "100%",
                  height: "100%",
                  autoplay: true,
                  format: "m3u8",
                  encryptType: "1",
                  isLive: false,
                  rePlay: false,
                  playsinline: true,
                  preload: true,
                  controlBarVisibility: "hover",
                  useH5Prism: true,
                  //支持播放地址播放,此播放优先级最高
                  //source: "http://cloud.video.taobao.com/play/u/2554695624/p/1/e/6/t/1/fv/102/28552077.mp4",
                  //播放方式二：点播用户推荐
                  vid: that.videoId,
                  playauth: result1.data,
                  //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
                  skinLayout: [
                    { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                    { name: "H5Loading", align: "cc" },
                    { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                    { name: "infoDisplay" },
                    { name: "tooltip", align: "blabs", x: 0, y: 56 },
                    { name: "thumbnail" },
                    {
                      name: "controlBar",
                      align: "blabs",
                      x: 0,
                      y: 0,
                      children: [
                        { name: "progress", align: "blabs", x: 0, y: 44 },
                        { name: "playButton", align: "tl", x: 15, y: 12 },
                        { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                        // { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                        // { name: "setting", align: "tr", x: 15, y: 12 },
                        { name: "volume", align: "tr", x: 5, y: 10 },
                      ],
                    },
                  ],
                },
                function (player) {
                  that.previewEnd = false;
                  // 准备播放
                  player.on("ready", function () {
                    let vid = document.querySelector("#J_prismPlayer video");
                    if (that.videoMutePlay) {
                      player.mute();
                    }
                  });
                  // 暂停
                  player.on("pause", function () {
                    clearInterval(that.currentTimes); // 清除定时器
                    that.currentTimes = null;
                  });
                  // 恢复暂停
                  player.on("play", function () {
                    that.getCurrentTimes();
                  });
                  // 播放结束
                  player.on("ended", function () {
                    that.toPlaybackRecord();
                    that.$emit("ended");
                  });
                }
              );
            }, 500);
          }
        });
    },
    // 关闭视频
    dd() {
      this.player.dispose();
      this.player = null;
    },
    // 开始播放 - 记录播放时长
    getCurrentTimes() {
      this.currentTimes = setInterval(() => {
        this.Times++;
      }, 1000);
    },
    // 发送播放记录
    toPlaybackRecord(){
      let data = {
        watchTime: this.Times
      };
      if (this.courseId) {
        data.courseId = this.courseId;
      }
      if (this.kpointId) {
        data.kpointId = this.kpointId;
      }
      if (this.courseId || this.kpointId) {
        this.$post("/biz/course/saveOrUpdateWatchLog", data).then((res) => {
          clearInterval(this.currentTimes); // 清除定时器
          this.currentTimes = null;
          this.Times = 0;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
